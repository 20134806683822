interface IRomanDictionary {
  [name: string]: number
}

const romanDictionary: IRomanDictionary = {
  M: 1000,
  CM: 900,
  D: 500,
  CD: 400,
  C: 100,
  XC: 90,
  L: 50,
  XL: 40,
  X: 10,
  IX: 9,
  V: 5,
  IV: 4,
  I: 1,
}

/**
 * Shamelessly plugged from:
 * https://stackoverflow.com/questions/9083037/convert-a-number-into-a-roman-numeral-in-javascript
 *
 * Thanks!
 */
export const toRomanNumbers = (num: number): string => {
  let str = ""

  for (const i of Object.keys(romanDictionary)) {
    const q = Math.floor(num / romanDictionary[i])

    num -= q * romanDictionary[i]
    str += i.repeat(q)
  }

  return str
}
