import React from "react"
import styled from "styled-components"

// Types
import { IRichText } from "../types"

interface IProps extends IRichText {
  className?: string
}

// Components
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"

const RichText = ({ className, html }: IProps) => (
  <Wrapper className={className}>
    <AnimatedScrollWrapper>
      <TextElem dangerouslySetInnerHTML={{ __html: html }} />
    </AnimatedScrollWrapper>
  </Wrapper>
)

// Main styles
const Wrapper = styled.div`
  margin-bottom: calc(var(--base-column-size) / 2);
`

const TextElem = styled.div`
  *:last-child {
    margin-bottom: 0;
  }
`

export default RichText
