import React from "react"

// Types
import { ISlice } from "../../types"

// Components
import { Row, Col } from "../layout/Grid"
import Container from "../layout/Container"
import Heading from "../Heading"
import RichText from "../RichText"
import TextBlock from "../TextBlock"
import TextColumns from "../TextColumns"
import TextMedia from "../TextMedia"
import Image from "../Image"
import ImageGrid from "../ImageGrid"
import Video from "../Video"
import List from "../List"
import Facts from "../Facts"
import Quote from "../Quote"
import Tribe from "../Tribe"
import CaseReferences from "../CaseReferences"
import ContactBanner from "../ContactBanner"
import ContactBlocks from "../ContactBlocks"
import PrincipleHeading from "../PrincipleHeading"
import TextRoundedImg from "../TextRoundedImg"

export const renderSlice = (slice: ISlice) => {
  switch (slice.type) {
    case "heading":
      return (
        <Container>
          <Row>
            <Col m={12} mOffset={2}>
              <Heading {...slice.data} />
            </Col>
          </Row>
        </Container>
      )
    case "rich_text":
      return (
        <Container>
          <Row>
            <Col xxs={6} xxsOffset={1} m={10} mOffset={3}>
              <RichText {...slice.data} />
            </Col>
          </Row>
        </Container>
      )
    case "text_block":
      return <TextBlock {...slice.data} />
    case "text_columns":
      return (
        <Container>
          <Row>
            <Col xxs={6} xxsOffset={1} m={12} mOffset={2}>
              <TextColumns {...slice.data} />
            </Col>
          </Row>
        </Container>
      )
    case "text_media":
      return <TextMedia {...slice.data} />
    case "image":
      return <Image {...slice.data} />
    case "image_grid":
      return <ImageGrid {...slice.data} />
    case "video":
      return <Video {...slice.data} />
    case "list":
      return <List {...slice.data} />
    case "facts":
      return (
        <Container>
          <Row>
            <Col xxs={6} xxsOffset={1} m={12} mOffset={2}>
              <Facts {...slice.data} />
            </Col>
          </Row>
        </Container>
      )
    case "quote":
      return (
        <Container>
          <Quote {...slice.data} />
        </Container>
      )
    case "tribe":
      return <Tribe {...slice.data} />
    case "case_references":
      return <CaseReferences {...slice.data} />
    case "contact_banner":
      return <ContactBanner {...slice.data} />
    case "contact_blocks":
      return <ContactBlocks {...slice.data} />
    case "principle_heading":
      return <PrincipleHeading {...slice.data} />
    case "text___rounded_image":
      return <TextRoundedImg {...slice.data} />
    default:
      return null
  }
}
