import React from "react"
import styled from "styled-components"

// Types
import { IHeading } from "../types/"

interface IProps extends IHeading {
  className?: string
}

// Components
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"

const Heading = ({ className, value }: IProps) => (
  <Wrapper className={className}>
    <AnimatedScrollWrapper>
      <HeadingElem>{value}</HeadingElem>
    </AnimatedScrollWrapper>
  </Wrapper>
)

// Main styles
const Wrapper = styled.div`
  margin-bottom: calc(var(--base-column-size) / 2);
`

const HeadingElem = styled.h2`
  margin-bottom: 0;
`

export default Heading
