import React from "react"
import styled, { css } from "styled-components"

// Types
import { IContactBlocks } from "../types/"
import { IBlockTheme } from "../types/prismic/ContactBlocks"

// Styling
import { mq } from "../styles/utils/mq"
import { fontWeights } from "../styles/fonts"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "./animation/AnimatedTextScrollWrapper"
import Link from "./generic/Link"

const ContactBlocks = React.memo(
  ({
    detailsTitle,
    detailsCopy,
    detailsLink,
    detailsBlockTheme = "dark",
    contactTitle,
    contactCopy,
    contactLink,
    contactBlockTheme = "accent",
  }: IContactBlocks) => (
    <Wrapper>
      <DetailsBlock theme={detailsBlockTheme}>
        {detailsTitle && (
          <TitleWrapper>
            <AnimatedTextScrollWrapper
              text={detailsTitle}
              textStyles={textStyles.headingM}
            />
          </TitleWrapper>
        )}

        {detailsCopy && detailsCopy.html && (
          <CopyWrapper>
            <AnimatedScrollWrapper>
              <div dangerouslySetInnerHTML={{ __html: detailsCopy.html }} />
            </AnimatedScrollWrapper>
          </CopyWrapper>
        )}

        {detailsLink && (
          <Link
            url={detailsLink.url}
            title={detailsLink.name}
            theme={detailsBlockTheme}
          />
        )}
      </DetailsBlock>

      <ContactBlock theme={contactBlockTheme}>
        {contactTitle && (
          <TitleWrapper>
            <AnimatedTextScrollWrapper
              text={contactTitle}
              textStyles={textStyles.headingM}
            />
          </TitleWrapper>
        )}

        {contactCopy && contactCopy.html && (
          <CopyWrapper>
            <AnimatedScrollWrapper>
              <div dangerouslySetInnerHTML={{ __html: contactCopy.html }} />
            </AnimatedScrollWrapper>
          </CopyWrapper>
        )}

        {contactLink && (
          <Link
            url={contactLink.url}
            title={contactLink.name}
            theme={contactBlockTheme}
          />
        )}
      </ContactBlock>
    </Wrapper>
  )
)

// Theme styles
const themeLightBlock = css`
  background-color: ${colors.white};
  color: ${colors.black};
`

const themeDarkBlock = css`
  background-color: ${colors.black};
  color: ${colors.white};
`

const themeAccentBlock = css`
  background-color: ${colors.accent2};
  color: ${colors.black};
`

const getBlockTheme = (theme: IBlockTheme) => {
  switch (theme) {
    case "light":
      return themeLightBlock
    case "dark":
      return themeDarkBlock
    default:
      return themeAccentBlock
  }
}

// Shared styles
const sharedBlockStyles = css`
  /* duplicate style because of IE */
  padding: 10vw;
  padding: var(--base-column-size);

  ${mq.from.M`
    width: 50%;
    padding-top: calc(2 * var(--base-column-size));
    padding-bottom: calc(2 * var(--base-column-size));
  `}

  p {
    margin-bottom: 0;
  }
`

// Main styles
const Wrapper = styled.div`
  ${mq.from.M`
    display: flex;
  `}
`

const DetailsBlock = styled.div<{ theme?: IBlockTheme }>`
  ${sharedBlockStyles};

  ${({ theme }) => (theme ? getBlockTheme(theme) : themeDarkBlock)};

  ${mq.from.M`
    padding-right: var(--base-column-size);
    padding-left: calc(2 * var(--base-column-size));
  `}
`

const ContactBlock = styled.div<{ theme?: IBlockTheme }>`
  ${sharedBlockStyles};

  ${({ theme }) => (theme ? getBlockTheme(theme) : themeAccentBlock)};

  ${mq.from.M`
    padding-right: calc(2 * var(--base-column-size));
    padding-left: var(--base-column-size);
  `}
`

const TitleWrapper = styled.div`
  margin-bottom: 32px;
`

const CopyWrapper = styled.div`
  &:not(:last-child) {
    /* duplicate style because of IE */
    margin-bottom: 2vh;
    margin-bottom: calc(var(--base-column-size) / 2);
  }

  p {
    font-weight: ${fontWeights.semiBold};

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    ${textStyles.hyperlink};
  }
`

export default ContactBlocks
