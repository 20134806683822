import React from "react"
import styled, { css } from "styled-components"
import { Link as LinkComponent } from "gatsby"

// Types
import { ICaseReferences, ICaseReference, IOnOffType } from "../types/"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { fontWeights } from "../styles/fonts"

// Components
import { Row as RowComponent, Col as ColComponent } from "./layout/Grid"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "./animation/AnimatedTextScrollWrapper"

const CaseReferences = ({ items }: ICaseReferences) => (
  <Wrapper>
    {items.map((item: ICaseReference, index: number) => (
      <Item key={index}>
        <Row>
          <TextCol
            xxs={6}
            xxsOffset={1}
            m={12}
            mOffset={2}
            l={6}
            lOffset={2}
            isPadded={item.mediaHasPadding}
          >
            {item.title && (
              <Title>
                <AnimatedTextScrollWrapper
                  text={item.title}
                  textStyles={titleStyles}
                />
              </Title>
            )}

            {item.copy && item.copy.html && (
              <AnimatedScrollWrapper>
                <div dangerouslySetInnerHTML={{ __html: item.copy.html }}></div>
              </AnimatedScrollWrapper>
            )}
          </TextCol>

          <MediaCol xxs={8} m={16} l={6} lOffset={1}>
            {item.media && item.media.url && (
              <MediaWrapper isPadded={item.mediaHasPadding}>
                <AnimatedScrollWrapper>
                  {item.media.kind === "image" ? (
                    <MediaImage
                      src={item.media.url}
                      alt={item.media.alt}
                      isPadded={item.mediaHasPadding}
                    />
                  ) : (
                    <MediaVideo src={item.media.url} autoPlay loop muted>
                      <p>
                        If you are reading this, it is because your browser does
                        not support the HTML5 video element.
                      </p>
                    </MediaVideo>
                  )}
                </AnimatedScrollWrapper>
              </MediaWrapper>
            )}
            {item.linkName && item.link && (
              <LinkWrapper>
                <AnimatedScrollWrapper>
                  <span>
                    Example case:{" "}
                    <Link to={item.link.url}>{item.linkName}</Link>
                  </span>
                </AnimatedScrollWrapper>
              </LinkWrapper>
            )}
          </MediaCol>
        </Row>
      </Item>
    ))}
  </Wrapper>
)

// Shared styles
const sharedMediaStyles = css`
  object-fit: contain;
  width: 100%;

  ${mq.from.L`
    height: 100%;
  `}
`

// Main styles
const titleStyles = css`
  ${textStyles.headingS};
  font-weight: ${fontWeights.medium};
`

const Row = styled(RowComponent)`
  position: relative;
`

const TextCol = styled(ColComponent)<{ isPadded: IOnOffType }>`
  margin-bottom: var(--base-column-size);

  ${mq.from.L`
    padding-top: ${(props: { isPadded: IOnOffType }) =>
      props.isPadded === "on" ? "calc(100vh / 3)" : "calc(100vh / 16)"};
    margin-bottom: 0;
    padding-bottom: var(--base-column-size);
  `}
`

const MediaCol = styled(ColComponent)`
  ${mq.from.L`
    position: sticky;
    top: 0;
    height: 100vh;
    padding-top: var(--base-column-size);
    padding-bottom: var(--base-column-size);
  `}
`

const Wrapper = styled.ul`
  list-style: none;
  margin: 0;
`

const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: var(--base-column-size);
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const Title = styled.h1`
  margin-bottom: 32px;
`

const MediaWrapper = styled.div<{ isPadded: IOnOffType }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 8px;

  ${(props: { isPadded: IOnOffType }) => {
    return (
      props.isPadded === "on" &&
      `
        padding: var(--base-column-size);
        background-color: ${colors.lGrey};
      `
    )
  }}

  ${mq.from.L`
    height: 100%;
  `}
`

const MediaImage = styled.img`
  ${sharedMediaStyles};

  /* Tablets only */
  @media (min-width: ${mq.sizes.L}px) and (orientation: portrait) {
    ${(props: { isPadded: IOnOffType }) => {
      return props.isPadded === "off" && `max-height: 50vh;`
    }}
  }
`

const MediaVideo = styled.video`
  ${sharedMediaStyles};
  display: block;
  width: 100%;
`

const LinkWrapper = styled.div`
  padding-right: var(--base-column-size);
  padding-left: var(--base-column-size);

  ${mq.from.L`
    padding-right: 0;
    padding-left: 0;
  `}
`

const Link = styled(LinkComponent)`
  ${textStyles.hyperlink};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

export default CaseReferences
