import React from "react"
import styled from "styled-components"

// Types
import { IContactBanner } from "../types"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import { Row, Col } from "./layout/Grid"
import Container from "./layout/Container"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"

const ContactBanner = React.memo(({ title, details }: IContactBanner) => (
  <Wrapper>
    <Container>
      <Row>
        <Col m={12} mOffset={2}>
          {title && (
            <TitleWrapper>
              <AnimatedScrollWrapper>
                <Title>{title}</Title>
              </AnimatedScrollWrapper>
            </TitleWrapper>
          )}

          {details && details.html && (
            <AnimatedScrollWrapper>
              <Details dangerouslySetInnerHTML={{ __html: details.html }} />
            </AnimatedScrollWrapper>
          )}
        </Col>
      </Row>
    </Container>
  </Wrapper>
))

const Wrapper = styled.div`
  padding-top: var(--base-column-size);
  padding-bottom: var(--base-column-size);
  background-color: ${colors.accent2};
`

const TitleWrapper = styled.div`
  margin-bottom: 16px;
`

const Title = styled.h3`
  ${textStyles.headingS};
  margin-bottom: 0;
`

const Details = styled.div`
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    ${textStyles.hyperlink};
  }
`

export default ContactBanner
