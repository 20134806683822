import React from "react"
import { Link as LinkComponent } from "gatsby"
import styled, { css } from "styled-components"

// Styling
import { mq } from "../styles/utils/mq"
import { fontWeights } from "../styles/fonts"
import colors from "../styles/colors"

// Components
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import ArrowSVG from "./icons/arrow"

type ITypeFeaturedItemPosition = "first" | "last"
interface IImageGridItem {
  title: string
  description: string
  image: {
    url: string
    alt?: string
  }
  link?: {
    url: string
  }
}

interface IProps {
  items: IImageGridItem[]
  link?: {
    title: string
    url: string
  }
  featuredItemPosition: ITypeFeaturedItemPosition
}

const ImageGrid = ({ items, link, featuredItemPosition }: IProps) => (
  <Wrapper featuredItemPosition={featuredItemPosition}>
    {items.map((item: IImageGridItem, index: number) => (
      <Item key={index} featuredItemPosition={featuredItemPosition}>
        <ItemDetails>
          <AnimatedScrollWrapper>
            {item.title && <ItemCopy>{item.title}</ItemCopy>}
            {item.description && (
              <ItemCopy>&mdash; {item.description}</ItemCopy>
            )}
          </AnimatedScrollWrapper>
        </ItemDetails>

        {item.image.url && (
          <AnimatedScrollWrapper>
            {item.link && item.link.url ? (
              <>
                {item.link.url.startsWith("/") ? (
                  <ItemImageInternalLink to={item.link.url}>
                    <ItemImage src={item.image.url} alt={item.image.alt} />
                  </ItemImageInternalLink>
                ) : (
                  <ItemImageExternalLink href={item.link.url} target="_blank">
                    <ItemImage src={item.image.url} alt={item.image.alt} />
                  </ItemImageExternalLink>
                )}
              </>
            ) : (
              <ItemImage src={item.image.url} alt={item.image.alt} />
            )}
          </AnimatedScrollWrapper>
        )}
      </Item>
    ))}

    {link && link.title && link.url && (
      <GridLinkWrapper>
        <AnimatedScrollWrapper>
          <GridLink to={link.url}>
            <GridLinkIcon
              width={40}
              height={40}
              color={colors.white}
              direction="right"
            />
            <GridLinkText>{link.title}</GridLinkText>
          </GridLink>
        </AnimatedScrollWrapper>
      </GridLinkWrapper>
    )}
  </Wrapper>
)

// Generic styles
const firstFeaturedWrapperStyles = css`
  grid-template-areas:
    "featured featured"
    "featured featured"
    ". middle"
    "last link";

  ${mq.from.L`
    grid-template-areas:
      "featured featured . last"
      "featured featured middle last"
      "featured featured middle link";
  `}
`

const lastFeaturedWrapperStyles = css`
  grid-template-areas:
    "featured featured"
    "featured featured"
    ". middle"
    "last link";

  ${mq.from.L`
    grid-template-areas:
      "last . featured featured"
      "last middle featured featured"
      "link middle featured featured";
  `}
`

const featuredItemStyles = css`
  grid-area: featured;

  ${mq.from.L`
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    padding-bottom: 0;
  `}
`

const lastItemStyles = css`
  grid-area: last;

  ${mq.from.L`
    -ms-grid-row: 1;
    -ms-grid-column: 4;
  `}
`

// Main styles
/**
 * Workaround since IE 11 doesn't support the modern CSS grid syntax.
 * Normally, the prefixes should be generated by Autoprefixer, however
 * there are several issues with Gatsby not working correctly in this circumstance.
 *
 * As an alternative, the prefixes have been added manually for desktop (L) sizes.
 * Have fun decoding!
 *
 * PS: Keep in mind, IE 11 doesn't support grid gaps, so they have to be emulated
 * PS: by creating additional rows and columns as placeholders for the gaps.
 * PS: That means, counting the starting rows/cols includes the gaps!
 */
const Wrapper = styled.div<{ featuredItemPosition: ITypeFeaturedItemPosition }>`
  ${mq.from.M`
    display: -ms-grid;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "featured featured"
      "featured featured"
      ". middle"
      "last link";

    ${({
      featuredItemPosition,
    }: {
      featuredItemPosition: ITypeFeaturedItemPosition
    }) =>
      featuredItemPosition === "last"
        ? lastFeaturedWrapperStyles
        : firstFeaturedWrapperStyles};
  `}

  ${mq.from.L`
    -ms-grid-rows: 1fr 1fr 1fr;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-top: 64px;
  `}

${mq.to.S`
display: block;
`}
`

// Item
const Item = styled.div<{ featuredItemPosition: ITypeFeaturedItemPosition }>`
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: var(--base-column-size);

  /* Fix alignment issue due to the default li element styling */
  line-height: 0;

  ${mq.from.M`
    margin-bottom: 0;
  `}

  &:nth-child(1) {
    ${({
      featuredItemPosition,
    }: {
      featuredItemPosition: ITypeFeaturedItemPosition
    }) =>
      featuredItemPosition === "last" ? lastItemStyles : featuredItemStyles};
  }

  &:nth-child(2) {
    grid-area: middle;

    ${mq.from.L`
      -ms-grid-row: 2;
      -ms-grid-column: 3;
    `}
  }

  &:nth-child(3) {
    ${({
      featuredItemPosition,
    }: {
      featuredItemPosition: ITypeFeaturedItemPosition
    }) =>
      featuredItemPosition === "last" ? featuredItemStyles : lastItemStyles};
  }

  /* style because of IE */
  ${mq.from.M &&
    mq.to.L`
    &:nth-child(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    &:nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }

    &:nth-child(3) {
      -ms-grid-row: 2;
      -ms-grid-column: 1;
    }

    &:nth-child(4) {
      -ms-grid-row: 2;
      -ms-grid-column: 2;
    }
  `}
`

const ItemDetails = styled.div`
  margin-bottom: 16px;
  /* duplicate style because of IE */
  padding-left: 10vw;
  padding-left: var(--base-column-size);

  ${mq.from.M`
    padding-top: var(--base-column-size);
  `}

  ${mq.from.L`
    position: absolute;
    top: -24px;
    padding-top: 0;
    transform: translateY(-100%);
  `}
`

const ItemCopy = styled.p`
  margin: 0;
  padding-right: 12px;
  color: ${colors.white};
`

const ItemImageInternalLink = styled(LinkComponent)`
  display: block;
`

const ItemImageExternalLink = styled.a`
  display: block;
`

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const GridLinkWrapper = styled.div`
  display: flex;
  grid-area: link;
  justify-content: flex-start;
  padding: 0 8px;

  ${mq.from.M`
    justify-content: center;
    align-items: flex-end;
    padding: 0;
  `}

  ${mq.from.L`
    -ms-grid-row: 3;
    -ms-grid-column: 4;
  `}
`

// Grid link
const GridLink = styled(LinkComponent)`
  display: flex;
  align-items: center;
  margin-left: var(--base-column-size);
  text-decoration: none;

  ${mq.from.M`
    flex-direction: column;
    margin-left: 0;
  `}

  &:hover {
    svg {
      fill: ${colors.accent1};
    }
  }
`

const GridLinkIcon = styled(ArrowSVG)`
  ${mq.from.M`
    margin-bottom: 1em;
  `}
`

const GridLinkText = styled.div`
  padding-left: 20px;
  color: ${colors.white};
  text-transform: uppercase;
  font-weight: ${fontWeights.bold};

  ${mq.from.M`
    padding-left: 0;
  `}
`

export default ImageGrid
