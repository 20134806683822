import React from "react"

// Types
import { IVacancy } from "../types/components/Vacancy"

// Styling
import colors from "../styles/colors"
import {
  Wrapper,
  Article,
  Content,
  Metadata,
  ArticleLink,
} from "../styles/components/ArticleList"

// Components
import Divider from "./layout/Divider"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"

interface IProps {
  vacancies: IVacancy[]
}

const VacancyList = ({ vacancies }: IProps) => (
  <Wrapper>
    {vacancies.map((vacancy: IVacancy, index: number) => (
      <Article key={index}>
        <AnimatedScrollWrapper>
          <Divider color={colors.white} />
        </AnimatedScrollWrapper>

        <AnimatedScrollWrapper>
          <Content>
            <Metadata>
              <p>
                {vacancy.date && vacancy.date}
                <br />
                {vacancy.type && `— ${vacancy.type}`}
              </p>
            </Metadata>

            {vacancy.slug && vacancy.title && (
              <ArticleLink to={`/vacancy/${vacancy.slug}`}>
                {vacancy.title}
              </ArticleLink>
            )}
          </Content>
        </AnimatedScrollWrapper>
      </Article>
    ))}
  </Wrapper>
)

export default VacancyList
