import React from "react"
import { graphql } from "gatsby"

// Utils
import {
  formatPrismicData,
  formatVacancy,
} from "../utils/helpers/prismicHelpers"

// Components
import VacanciesPage from "../pageViews/vacanciesPageView"

// Local utils
const formatVacancies = (vacancies: any) => {
  if (!vacancies) {
    return []
  }

  return vacancies.nodes.map((post: any) => formatVacancy(post))
}

const VacanciesPageContainer = ({ data }: any) => {
  if (!data.vacanciesPage) {
    return null
  }

  const rawData = data.vacanciesPage.data
  const formattedVacanciesPageData = {
    metaTitle: rawData.metaTitle,
    metaDescription: rawData.metaDescription,
    pageTitle: rawData.page_title,
    introTitle: rawData.intro_title,
    introCopy: rawData.intro_copy,
  }

  const vacancies = formatVacancies(data.allVacancies)

  const slices = rawData.slices
    .filter((slice: any) => slice.type && slice)
    .map((slice: any) => formatPrismicData(slice))

  return (
    <VacanciesPage
      data={formattedVacanciesPageData}
      vacancies={vacancies}
      slices={slices}
    />
  )
}

export const query = graphql`
  query GetVacanciesPage {
    vacanciesPage: prismicVacanciesPage {
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        page_title
        intro_title
        intro_copy {
          html
        }
        slices: body {
          ... on PrismicVacanciesPageBodyImage {
            type: slice_type
            id
            primary {
              image {
                alt
                url
              }
              image_size
            }
          }
          ... on PrismicVacanciesPageBodyTextMedia {
            type: slice_type
            id
            primary {
              text_media_title {
                raw {
                  text
                }
              }
              text_media_copy {
                html
              }
              text_media_media {
                kind
                link_type
                url
                alt: name
              }
              text_media_media_alignment
              text_media_media_padding
              text_media_has_spacing
              text_media_link_title
              text_media_link {
                link_type
                url
                target
              }
            }
          }
        }
      }
    }
    allVacancies: allPrismicVacancy(
      sort: { fields: data___publication_date, order: DESC }
    ) {
      nodes {
        slug: uid
        data {
          title {
            text
          }
          headline
          vacancy_type
          publication_date(formatString: "DD/MM/YYYY")
        }
      }
    }
  }
`

export default VacanciesPageContainer
