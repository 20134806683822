import React from "react"

// Types
import { IIcon } from "../../types/"

const QuoteSVG = ({ className, width, height, color }: IIcon) => (
  <svg
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 40 32"
    fill={color}
  >
    <path
      d="M16.464 32V16.354H8.95c0-4.862 3.536-8.62 7.846-9.945V0C12.376.442 7.735 2.43 4.75 5.525 1.768 8.508 0 12.597 0 18.343V32h16.464zm23 0V16.354H31.95c0-4.862 3.536-8.62 7.846-9.945V0c-4.42.442-9.061 2.43-12.045 5.525C24.768 8.508 23 12.597 23 18.343V32h16.464z"
      fillRule="nonzero"
    />
  </svg>
)

export default QuoteSVG
