import React from "react"
import styled from "styled-components"

// Types
import { ITribe, ITribeMember } from "../types/"

// Styles
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"

// Components
import { Row, Col } from "./layout/Grid"
import Container from "./layout/Container"
import Divider from "./layout/Divider"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"

const Tribe = ({ name, items }: ITribe) => {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col m={14} mOffset={1}>
            {name && (
              <Header>
                <AnimatedScrollWrapper>
                  <Divider color={colors.white} />
                </AnimatedScrollWrapper>

                <AnimatedScrollWrapper>
                  <TribeName>{name}</TribeName>
                </AnimatedScrollWrapper>
              </Header>
            )}

            <MemberList>
              {items.map((item: ITribeMember, index: number) => (
                <Member key={index}>
                  <AnimatedScrollWrapper>
                    {item.thumbnail && item.thumbnail.url && (
                      <MemberImage src={item.thumbnail.url} />
                    )}
                  </AnimatedScrollWrapper>

                  <AnimatedScrollWrapper>
                    {item.name && <Copy>{item.name}</Copy>}
                    {item.jobTitle && <Copy>&mdash; {item.jobTitle}</Copy>}
                  </AnimatedScrollWrapper>
                </Member>
              ))}
            </MemberList>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

// Generic styles
const Copy = styled.div`
  color: ${colors.white};
`

// Main styles
const Wrapper = styled.div``

const Header = styled.div`
  margin-bottom: calc(var(--base-column-size) / 2);
`

const TribeName = styled.h2`
  margin-bottom: 0;
`

const MemberList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;

  /* Offset the first and last items margins' */
  margin-right: calc(-1 * var(--base-column-size) / 4);
  margin-bottom: calc(-1 * var(--base-column-size) / 2);
  margin-left: calc(-1 * var(--base-column-size) / 4);
`

const Member = styled.li`
  margin-bottom: calc(var(--base-column-size) / 2);

  /* Set some spacing around each item */
  padding-right: calc(var(--base-column-size) / 4);
  padding-left: calc(var(--base-column-size) / 4);

  ${mq.from.XS`
    width: 50%;
  `}

  ${mq.from.L`
    width: 25%;
  `}
`

const MemberImage = styled.img`
  margin-bottom: calc(var(--base-column-size) / 8);
`

export default Tribe
