import React from "react"
import styled from "styled-components"
import { Row, Col } from "./layout/Grid"
import { mq } from "../styles/utils/mq"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import { ITextRoundedImg } from "../types/prismic/TextRoundedImg"

const TextRoundedImg: React.FC<ITextRoundedImg> = ({
  title,
  copy,
  image,
  heading,
  contactName,
  contactTitle,
  underlinedParagraph,
}) => (
  <AnimatedScrollWrapper>
    <Row>
      <Container>
        <TextCol xxs={8} m={16} l={6} lOffset={0}>
          <Texts>
            <div>{title.html.replace(/<[^>]*>/g, "")}</div>
            <div>{copy}</div>
            <TextContainer>
              <div>
                <p> {heading}</p>
              </div>
              <div>
                <p>{contactName}</p>
                <p>{contactTitle}</p>
                <EmailContainer>{underlinedParagraph}</EmailContainer>
              </div>
            </TextContainer>
          </Texts>
        </TextCol>
        <ImageCol xxs={8} m={16} l={7} lOffset={3}>
          <ImageContainer>
            <RoundedImage src={image.url} alt=""></RoundedImage>
          </ImageContainer>
        </ImageCol>
      </Container>
    </Row>
  </AnimatedScrollWrapper>
)
const ImageCol = styled(Col)`
  display: flex;
  justify-content: center;
  ${mq.from.M`
    justify-content: right;
    margin-left: 0px;
    width: 37.5%;
  `}
`
const TextCol = styled(Col)`
  ${mq.from.M`
  width: 50%;
  `}
`

const Container = styled.div`
background: #F6F6F6;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;

${mq.from.M`
flex-direction: row;
justify-content: space-between;
`}
}
`

const RoundedImage = styled.img`
  width: 96vw;
  height: 96vw;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;

  ${mq.from.M`
width: 100%;
height: 100%;
`}
`

const ImageContainer = styled.div`
  display: flex;
  margin: 8px;
  justify-content: center;

  ${mq.from.M`
  width: 37vw;
  height: 37vw;
  margin-left: 0px;
  `}
`

const Texts = styled.div`
color: black;
line-height: 1.125; 
font-size: calc(24px + (40 - 24) * ((100vw - 320px) / (768 - 320)));
letter-spacing: -0.27px;
padding-left:8px;

  ${mq.from.M`
  font-size: calc(24px + (33 - 24) * ((100vw - 320px) / (768 - 320)));
  padding-left:8px;
  `}

  ${mq.from.L`
  padding-left:16px;
  `}

  ${mq.from.XL`
  font-size: calc(42px + 24 * ((100vw - 1280px) / 640));
  `}

  ${mq.from.XXL`
  font-size: calc(var(--base-fluid-font-size) * 5);
  `}

  div.text-container{
    margin-top: 15px;

    ${mq.from.M`
    display: flex;
    justify-content: space-between;
    `}
  }
  p{
    margin-bottom:8px;
  }
}
`
const EmailContainer = styled.p`
  text-decoration: none;
  margin-bottom: calc(var(--base-column-size) / 2);
  ${mq.from.M`
  text-decoration: underline;
  `}
`
const TextContainer = styled.div`
  margin-top: 15px;

  ${mq.from.M`
display: flex;
justify-content: space-between;
`}
`
export default TextRoundedImg
