import React from "react"
import styled from "styled-components"

// Types
import { IList, IListItem } from "../types/"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import { Row, Col } from "./layout/Grid"
import Container from "./layout/Container"
import Divider from "./layout/Divider"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"

const List = ({ title, items }: IList) => {
  return (
    <Wrapper>
      <Container>
        {title && (
          <Row>
            <Col m={14} mOffset={1}>
              <TitleWrapper>
                <AnimatedScrollWrapper>
                  <Divider color={colors.white} />
                </AnimatedScrollWrapper>

                <Title>
                  <AnimatedScrollWrapper>{title}</AnimatedScrollWrapper>
                </Title>
              </TitleWrapper>
            </Col>
          </Row>
        )}

        {items && items.length && (
          <Row>
            <Col xxs={7} xxsOffset={1} m={12} mOffset={3}>
              <ItemList>
                {items.map((item: IListItem, index: number) => (
                  <Item
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item.html }}
                  />
                ))}
              </ItemList>
            </Col>
          </Row>
        )}
      </Container>
    </Wrapper>
  )
}

// Main styles
const Wrapper = styled.div``

const TitleWrapper = styled.div`
  margin-bottom: var(--base-column-size);

  ${mq.from.M`
    margin-bottom: calc(2 * var(--base-column-size));
  `}
`

const Title = styled.h3`
  ${textStyles.headingS};
`

const ItemList = styled.ul`
  list-style: none;
  margin: 0;

  ${mq.from.M`
    display: flex;
  `}
`

const Item = styled.li`
  margin-bottom: 0;
  padding-left: calc(var(--base-column-size) / 4);
  border-left: 1px solid ${colors.white};

  &:not(:last-child) {
    padding-bottom: calc(var(--base-column-size) / 2);
  }

  ${mq.from.M`
    flex-basis: 33.33%;
    padding-bottom: calc(var(--base-column-size) * 1.5);
  `}
`

export default List
