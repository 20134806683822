import React, { useContext } from "react"
import styled from "styled-components"

// Types
import { IFact } from "../types/"

// Utils
import { toRomanNumbers } from "../utils/helpers/stringHelpers"
import PageContext from "../utils/context/PageContext"

// Styling
import { mq } from "../styles/utils/mq"
import { fontWeights } from "../styles/fonts"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import Divider from "./layout/Divider"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"

type TTheme = "light" | "dark"

interface IProps {
  title: string
  items: IFact[]
}

// Utils
const getThemeColor = (theme: TTheme) =>
  theme === "light" ? colors.black : colors.white

const Facts = React.memo(({ title, items }: IProps) => {
  const page = useContext(PageContext)

  const theme: TTheme = page.color === colors.white ? "light" : "dark"

  return (
    <Wrapper theme={theme}>
      <Header>
        <AnimatedScrollWrapper>
          <Divider color={getThemeColor(theme)} />
        </AnimatedScrollWrapper>

        <AnimatedScrollWrapper>
          {title && <Title>{title}</Title>}
        </AnimatedScrollWrapper>
      </Header>

      {items &&
        items.map((item: IFact, index: number) => (
          <Item key={index}>
            <AnimatedScrollWrapper>
              <Divider color={getThemeColor(theme)} />
            </AnimatedScrollWrapper>

            <AnimatedScrollWrapper>
              <ContentWrapper>
                <ItemNr>{toRomanNumbers(index + 1)}.</ItemNr>

                <Content>
                  {item.title && <ItemTitle>{item.title}</ItemTitle>}
                  {item.copy && <ItemCopy>{item.copy}</ItemCopy>}

                  {item.sourceName && item.sourceName && (
                    <Source>
                      <SourceLink href={item.sourceLink.url} target="_blank">
                        Source: {item.sourceName}
                      </SourceLink>
                    </Source>
                  )}
                </Content>
              </ContentWrapper>
            </AnimatedScrollWrapper>
          </Item>
        ))}
    </Wrapper>
  )
})

// Main styles
const Wrapper = styled.ul<{ theme: TTheme }>`
  list-style: none;
  margin: 0;
  padding-top: var(--base-column-size);
  padding-bottom: var(--base-column-size);
  ${({ theme }) => `color: ${getThemeColor(theme)};`}

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`

const Header = styled.div`
  margin-bottom: var(--base-column-size);
`

const Title = styled.h3`
  ${textStyles.body};
  font-weight: ${fontWeights.medium};
  margin-bottom: 0;
`

const Item = styled.li`
  position: relative;
  margin-bottom: var(--base-column-size);

  &:last-child {
    margin-bottom: 0;
  }
`

const ItemNr = styled.p`
  position: absolute;
  top: 0;
  left: 0;
`

const ContentWrapper = styled.div`
  display: flex;
`

const Content = styled.div`
  /* Setting the max-width enables the text wrapping property below to work properly */
  max-width: 100%;
  padding-left: var(--base-column-size);

  ${mq.from.M`
    padding-left: calc(2 * var(--base-column-size));
  `}
`

const ItemTitle = styled.h4`
  ${textStyles.headingL};
  margin-bottom: calc(var(--base-column-size) / 6);
  overflow-wrap: break-word;
`

const ItemCopy = styled.p`
  line-height: 1.25;

  &:last-child {
    margin-bottom: 0;
  }
`

const Source = styled.p``

const SourceLink = styled.a`
  ${textStyles.hyperlink};
`

export default Facts
