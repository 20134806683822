import React from "react"
import styled from "styled-components"

// Styling
import { fontWeights } from "../../styles/fonts"
import colors from "../../styles/colors"
import textStyles from "../../styles/textStyles"

// Components
import { Row, Col } from "./Grid"
import Container from "./Container"
import Divider from "./Divider"
import AnimatedScrollWrapper from "../animation/AnimatedScrollWrapper"

interface IProps {
  pageTitle?: string
  introTitle: string
  introCopy: {
    html: string
  }
}

const PageHeader = ({ pageTitle, introTitle, introCopy }: IProps) => (
  <Wrapper>
    <Container>
      <AnimatedScrollWrapper>
        <Divider color={colors.white} />
      </AnimatedScrollWrapper>

      <Row>
        <Col m={6}>
          {pageTitle && (
            <AnimatedScrollWrapper>
              <PageTitle>{pageTitle}</PageTitle>
            </AnimatedScrollWrapper>
          )}
        </Col>

        <Col xxs={9} xxsOffset={0} m={9} mOffset={0}>
          {introTitle && (
            <IntroTitleWrapper>
              <AnimatedScrollWrapper>
                <IntroTitle>{introTitle}</IntroTitle>
              </AnimatedScrollWrapper>
            </IntroTitleWrapper>
          )}

          {introCopy && introCopy.html && (
            <AnimatedScrollWrapper>
              <IntroTitleWrapper>
                <IntroCopy
                  dangerouslySetInnerHTML={{
                    __html: introCopy.html,
                  }}
                />
              </IntroTitleWrapper>
            </AnimatedScrollWrapper>
          )}
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

// Main styles
const Wrapper = styled.div`
  margin-bottom: calc(var(--base-column-size) * 2);
`

const PageTitle = styled.h1`
  ${textStyles.body};
  margin-bottom: 0;
  font-weight: ${fontWeights.medium};
`

const IntroTitleWrapper = styled.div`
  margin-bottom: calc(var(--base-column-size) / 2);
`

const IntroTitle = styled.h2`
  ${textStyles.headingS};
  margin-bottom: 0;
`

const IntroCopy = styled.div`
  a {
    ${textStyles.hyperlink};
    color: ${colors.white};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  *: div last-child {
    ${textStyles.hyperlink};
    margin-bottom: 0;
  }
`

export default PageHeader
