import React from "react"
import styled from "styled-components"

// Types
import { ITextBlock } from "../types"

// Styles
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import { Row, Col } from "./layout/Grid"
import Container from "./layout/Container"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import Link from "./generic/Link"

interface IProps extends ITextBlock {
  className?: string
}

// Utils
const TextBlock = ({
  className,
  title,
  text,
  linkTitle,
  link,
  alignment,
}: IProps) => {
  // !: alignment determines the offset

  const mOffset = alignment === "left" ? 2 : "center" ? 4 : 6

  return (
    <Wrapper className={className}>
      <Container>
        <Row>
          <Col xxs={7} xxsOffset={1} m={8} mOffset={mOffset}>
            {title && (
              <Title>
                <AnimatedScrollWrapper>{title}</AnimatedScrollWrapper>
              </Title>
            )}

            {text && (
              <TextWrapper>
                <AnimatedScrollWrapper>
                  <Text dangerouslySetInnerHTML={{ __html: text.html }} />
                </AnimatedScrollWrapper>
              </TextWrapper>
            )}

            {linkTitle && link && (
              <>
                {link.target === "_blank" ? (
                  <Link
                    type="external"
                    url={link.url}
                    title={linkTitle}
                    color={colors.white}
                  />
                ) : (
                  <Link url={link.url} title={linkTitle} color={colors.white} />
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

// Main styles
const Wrapper = styled.div``

const Title = styled.h3`
  ${textStyles.headingS};
  margin-bottom: calc(var(--base-column-size) / 2);
`

const TextWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: calc(var(--base-column-size) / 4);
  }
`

const Text = styled.div`
  > :last-child,
  li:last-child {
    margin-bottom: 0;
  }
`

export default TextBlock
