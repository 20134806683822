import React from "react"
import styled from "styled-components"

// Components
import { Row, Col } from "./layout/Grid"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"

// Styles
import textStyles from "../styles/textStyles"
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"

// Types
import { IPrincipleHeading } from "../types/prismic/PrincipleHeading"

interface IProps extends IPrincipleHeading {
  className?: string
}

const PrincipleHeading: React.FC<IProps> = ({
  className,
  backgroundImage,
  title,
  principleNumber,
  mainImage,
}) => (
  <Wrapper className={className}>
    <Background background={backgroundImage.url}>
      {principleNumber && title && (
        <TextCol xxs={8} m={12} mOffset={2} l={5} lOffset={1}>
          <TextContainer>
            <AnimatedScrollWrapper>
              <PrincipleNumber>{principleNumber}</PrincipleNumber>
            </AnimatedScrollWrapper>
            <AnimatedScrollWrapper>
              <Title>{title}</Title>
            </AnimatedScrollWrapper>
          </TextContainer>
        </TextCol>
      )}
      {mainImage && mainImage.url && (
        <Col xxs={8} m={12} mOffset={2} l={7} lOffset={2}>
          <AnimatedScrollWrapper>
            <Image src={mainImage.url} alt={title} />
          </AnimatedScrollWrapper>
        </Col>
      )}
    </Background>
  </Wrapper>
)

export default PrincipleHeading

const Wrapper = styled.div`
  margin-bottom: calc(var(--base-column-size) / 2);
`

const Background = styled(Row)`
  height: 100%;
  width: 100%;
  background-image: url(${({ background }: { background: string }) =>
    background});
  background-size: cover;
  background-position: center center;
  padding: 12.5vw 12px;
  /* duplicate style because of IE */
  padding: var(--base-column-size) 12x;

  ${mq.from.M`
    padding: 6.25vw 0;
  `}
`

const TextCol = styled(Col)`
  display: flex;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  ${mq.from.L`
    align-self: center;
    margin-bottom: 0;
  `}
`

const PrincipleNumber = styled.h3`
  ${textStyles.headingXL};
  color: ${colors.white};
  margin: 0 0 16px;
`

const Title = styled.h3`
  margin: 0;
  ${textStyles.headingM};
`

const Image = styled.img`
  width: 100%;
`
