import React from "react"
import styled from "styled-components"

// Types
import { ITextColumns } from "../types/"

// Styling
import { from } from "../styles/utils/mq"

// Components
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"

const TextColumns = React.memo(({ left, right }: ITextColumns) => {
  return (
    <AnimatedScrollWrapper>
      <Wrapper>
        {left && (
          <LeftCol dangerouslySetInnerHTML={{ __html: left.html }}></LeftCol>
        )}
        {right && (
          <RightCol dangerouslySetInnerHTML={{ __html: right.html }}></RightCol>
        )}
      </Wrapper>
    </AnimatedScrollWrapper>
  )
})

const Wrapper = styled.ul`
  list-style: none;
  margin: 0;

  ${from.M`
    display: flex;
  `}
`

const Column = styled.li`
  margin-bottom: 24px;

  ${from.M`
    width: 50%;
    margin-bottom: 0;
  `}

  &:last-child {
    margin-bottom: 0;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`

const LeftCol = styled(Column)`
  ${from.M`
    padding-right: 8px;
  `}
`

const RightCol = styled(Column)`
  ${from.M`
    padding-left: 8px;
  `}
`

export default TextColumns
