import React from "react"
import styled from "styled-components"

// Types
import { IIcon } from "../../types"

type IDirectionType = "up" | "right" | "down" | "left"

interface IArrowIcon extends IIcon {
  direction?: IDirectionType
}

// Utils
const getDirectionStyles = (direction: IDirectionType) => {
  switch (direction) {
    case "down":
      return "rotate(180deg)"
    case "right":
      return "rotate(90deg)"
    case "left":
      return "rotate(-90deg)"
    default:
      return "initial"
  }
}

const Arrow = ({
  className,
  width,
  height,
  color,
  direction = "up",
}: IArrowIcon) => (
  <Wrapper
    className={className}
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 40 40"
    fill={color}
    direction={direction}
  >
    <path d="M2 18L20 0l18 18-6 6-7-7v23H15V17l-7 7-6-6z" fillRule="evenodd" />
  </Wrapper>
)

// Main styles
const Wrapper = styled.svg<{ direction: IDirectionType }>`
  ${({ direction }) =>
    direction &&
    direction !== "up" &&
    `
    transform-origin: center center;
    transform: ${getDirectionStyles(direction)}
`}
`

export default Arrow
