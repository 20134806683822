import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import Observer from "react-intersection-observer"

// Styling
import { fadeIn } from "../../styles/animations"
import colors from "../../styles/colors"
import textStyles from "../../styles/textStyles"
import { Link } from "gatsby"

interface IProps {
  className?: string
  alt?: string
  linkTo?: string
}

interface IImageProps extends IProps {
  src: string
  mode: "img" | "bg"
  inView: boolean
  observerRef?: () => HTMLElement | null
}

interface IStyledComponentProps extends IProps {
  error?: boolean
}

const Image: React.FC<IImageProps> = ({
  mode,
  inView,
  alt,
  src,
  linkTo,
  observerRef,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [unmounted, setUnmounted] = useState(false)

  const startLoadingImage = () => {
    const image = new (window as any).Image()
    image.onload = handleLoad()
    image.onerror = handleError()
    image.src = src
  }

  const handleLoad = () => {
    if (!unmounted) {
      setLoaded(true)
    }
  }

  const handleError = () => {
    if (!unmounted) {
      setLoaded(true)
      setError(true)
    }
  }

  useEffect(() => {
    if (inView) {
      startLoadingImage()
    }
    return () => {
      setUnmounted(true)
    }
  }, [inView])

  return inView ? (
    <Container ref={observerRef} {...props}>
      {loaded && mode === "bg" && (
        <BackgroundImg url={src} error={error} role="img" aria-label={alt} />
      )}
      {loaded &&
        mode === "img" &&
        (linkTo ? (
          <Link to={linkTo}>
            <Img alt={alt} src={src} error={error} {...props} />
          </Link>
        ) : (
          <Img alt={alt} src={src} error={error} {...props} />
        ))}
    </Container>
  ) : (
    <NotInViewport ref={observerRef} {...props} />
  )
}

const LazyLoad: React.FC<
  IProps & {
    src: string
    mode?: "img" | "bg"
    lazyLoad?: boolean
  }
> = props => {
  const setProps: IProps & {
    src: string
    mode: "img" | "bg"
    lazyLoad: boolean
  } = {
    mode: "img",
    lazyLoad: true,
    ...props,
  }

  return setProps.lazyLoad ? (
    <Observer rootMargin="0px 0px 100px 0px" triggerOnce>
      {({
        inView,
        ref,
      }: {
        inView: boolean
        ref: () => HTMLElement | null
      }) => <Image {...setProps} observerRef={ref} inView={inView} />}
    </Observer>
  ) : (
    <Image {...setProps} inView />
  )
}

const Container = styled.span`
  position: relative;
  display: flex;
`

const errorStyles = ({ error }: IStyledComponentProps) =>
  error &&
  css`
    color: ${colors.accent1};
    ${textStyles.body}
    line-height: 5;
    text-indent: 2em;
  `

const animationStyles = css`
  opacity: 0;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-duration: 0.6s;
  animation-name: ${fadeIn};
`

export const BackgroundImg = styled.div<
  IStyledComponentProps & { url: string }
>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("${props => props.url}");
  background-position: 50% 50%;
  background-size: cover;
  ${animationStyles};
  ${errorStyles};
`

const Img = styled.img<IStyledComponentProps & { src: string }>`
  align-self: center;
  display: flex;
  width: 100%;
  ${animationStyles};
  ${errorStyles};
`

const NotInViewport = styled.span`
  display: inline-block;
`

export default LazyLoad
