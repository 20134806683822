import React from "react"
import styled, { css } from "styled-components"

// Types
import { ITextMedia, IOnOffType } from "../types/"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import { Row as RowComponent, Col as ColComponent } from "./layout/Grid"
import Container from "./layout/Container"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "./animation/AnimatedTextScrollWrapper"
import Link from "./generic/Link"
import Divider from "./layout/Divider"

const TextMedia: React.FC<ITextMedia> = ({
  title,
  copy,
  linkTitle,
  link,
  media,
  mediaAlignment,
  mediaHasPadding,
  borderBottom,
  borderTop,
  exampleLinkTitle,
  exampleLinkText1,
  exampleLinkText2,
  exampleLinkText3,
  exampleLinkUrl1,
  exampleLinkUrl2,
  exampleLinkUrl3,
}) => {
  const renderTextCol = () => (
    <TextCol xxs={8} m={12} mOffset={2} l={6} lOffset={1}>
      {title && (
        <TextContainer>
          <Title>
            <AnimatedTextScrollWrapper
              text={title}
              textStyles={textStyles.headingS}
            />
          </Title>
        </TextContainer>
      )}

      {copy && copy.html && (
        <TextWrapper>
          <AnimatedScrollWrapper>
            <TextContainer>
              <Copy dangerouslySetInnerHTML={{ __html: copy.html }} />
              <Links>
                <div>
                  <label>{exampleLinkTitle}</label>{" "}
                  <ExampleLink
                    href={(exampleLinkUrl1 && exampleLinkUrl1.url) || ""}
                  >
                    {exampleLinkText1}
                  </ExampleLink>
                  {exampleLinkText2 && ", "}
                  <ExampleLink
                    href={(exampleLinkUrl2 && exampleLinkUrl2.url) || ""}
                  >
                    {exampleLinkText2}
                  </ExampleLink>
                  {exampleLinkText3 && ", "}
                  <ExampleLink
                    href={(exampleLinkUrl3 && exampleLinkUrl3.url) || ""}
                  >
                    {exampleLinkText3}
                  </ExampleLink>
                </div>
              </Links>
            </TextContainer>
          </AnimatedScrollWrapper>
        </TextWrapper>
      )}

      {linkTitle && link && (
        <ColComponent xxsOffset={1} xxs={6} mOffset={0} m={16}>
          {link.target === "_blank" ? (
            <Link
              type="external"
              url={link.url}
              title={linkTitle}
              color={colors.white}
              target={link.target}
            />
          ) : (
            <Link url={link.url} title={linkTitle} color={colors.white} />
          )}
        </ColComponent>
      )}
    </TextCol>
  )

  const renderMediaCol = () => (
    <MediaCol xxs={8} m={16} l={6} lOffset={0}>
      {media && media.url && (
        <MediaWrapper isPadded={mediaHasPadding}>
          <AnimatedMediaScrollWrapper>
            {media.kind === "image" ? (
              <MediaImage
                src={media.url}
                alt={media.alt}
                isPadded={mediaHasPadding}
              />
            ) : (
              <MediaVideo src={media.url} autoPlay loop muted>
                <p>
                  If you are reading this, it is because your browser does not
                  support the HTML5 video element.
                </p>
              </MediaVideo>
            )}
          </AnimatedMediaScrollWrapper>
        </MediaWrapper>
      )}
    </MediaCol>
  )

  return (
    <Wrapper>
      {borderTop === "on" && (
        <Container>
          <ColComponent xxs={8} m={14} mOffset={1}>
            {/* <StyledDivider color={colors.} /> */}
          </ColComponent>
        </Container>
      )}
      {mediaAlignment === "right" ? (
        <Row>
          {renderTextCol()}
          {renderMediaCol()}
        </Row>
      ) : (
        <Row>
          {renderMediaCol()}
          {renderTextCol()}
        </Row>
      )}
      {borderBottom === "on" && (
        <Container>
          <ColComponent>
            <StyledDivider color={colors.white} />
          </ColComponent>
        </Container>
      )}
    </Wrapper>
  )
}

// Shared styles
const sharedMediaStyles = css`
  object-fit: contain;
  width: 100%;

  ${mq.from.L`
    height: 100%;
  `}
`

const StyledDivider = styled(Divider)`
  border-top-width: 2px;
`

// Main styles
const Row = styled(RowComponent)`
  position: relative;
`

const TextCol = styled(ColComponent)`
  /* duplicate style because of IE */
  padding-top: 12.5vw;
  margin-bottom: 12.5vw;
  padding-top: var(--base-column-size);
  padding-bottom: var(--base-column-size);
  margin-bottom: var(--base-column-size);

  ${mq.from.L`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
  `}

  &:last-child {
    margin-bottom: 0;
  }
`

const MediaCol = styled(ColComponent)`
  padding-top: var(--base-column-size);
  padding-right: 14px;
  padding-left: 14px;

  ${mq.from.L`
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    height: 100vh;
    padding-right: 14px;
    padding-left: 14px;
    padding-bottom: var(--base-column-size);
  `}

  &:last-child {
    margin-bottom: 0;
  }
`

const TextContainer = styled(Container)`
  ${mq.from.M`
    padding-right: 0;
    padding-left: 0;
  `}
`

const Wrapper = styled.ul`
  list-style: none;
  margin: 0;
`

const Title = styled.h3`
  margin-bottom: 32px;

  ${mq.to.M`
    > div > div {
      font-size: calc(24px + (40 - 24) * ((100vw - 320px) / (768 - 320)));
    }
  `}
`

const TextWrapper = styled.div`
  &:not(:last-child) {
    /* duplicate style because of IE */
    margin-bottom: 3.125vw;
    margin-bottom: calc(var(--base-column-size) / 4);
  }
`

const Copy = styled.div`
  margin-bottom: calc(var(--base-column-size) / 2);
  p {
    &:last-child {
      margin-bottom: 0;
    }

    a {
      ${textStyles.hyperlink};
    }
  }
`

const MediaWrapper = styled.div<{ isPadded: IOnOffType }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${(props: { isPadded: IOnOffType }) => {
    return (
      props.isPadded === "on" &&
      `
        padding: var(--base-column-size);
        background-color: ${colors.lGrey};
      `
    )
  }}
`

const AnimatedMediaScrollWrapper = styled(AnimatedScrollWrapper)`
  width: 100%;

  > div {
    display: flex;
  }
`

const MediaImage = styled.img`
  ${sharedMediaStyles};
  height: 100%;

  /* Tablets only */
  @media (min-width: ${mq.sizes.L}px) and (orientation: portrait) {
    ${(props: { isPadded: IOnOffType }) => {
      return props.isPadded === "off" && `max-height: 50vh;`
    }}
  }
`

const MediaVideo = styled.video`
  ${sharedMediaStyles};
  display: block;
  width: 100%;
`

const Links = styled.div`
  span {
    text-decoration: underline;
  }
`
const ExampleLink = styled.a`
  color: white;
`
export default TextMedia
