import React from "react"
import styled from "styled-components"

// Types
import { IImage } from "../types"

// Components
import { Row, Col } from "./layout/Grid"
import LazyImage from "./helpers/LazyImage"

interface IProps extends IImage {
  className?: string
}

const Image = ({ url, alt, size, hasSpacing, className }: IProps) => {
  const wrapperHasSpacing = hasSpacing !== "off"

  return (
    <Wrapper className={className} hasSpacing={wrapperHasSpacing}>
      {size === "small" ? (
        <Row>
          <Col xxs={6} xxsOffset={1} m={10} mOffset={3}>
            <LazyImage src={url} alt={alt} />
          </Col>
        </Row>
      ) : (
        <LazyImage src={url} alt={alt} />
      )}
    </Wrapper>
  )
}

// Main styles
const Wrapper = styled.div<{ hasSpacing: boolean }>`
  ${({ hasSpacing }) => hasSpacing && "margin-bottom: var(--base-column-size);"}
`

export default Image
