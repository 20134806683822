import React, { useContext, useEffect } from "react"
import styled from "styled-components"

// Types
import { IPage, ISlice, IOnOffType, IVacancy } from "../types/"

// Styles
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Utils
import PageContext from "../utils/context/PageContext"

// Components
import { renderSlice } from "../components/helpers/slice"
import { Row, Col } from "../components/layout/Grid"
import Container from "../components/layout/Container"
import PageHeader from "../components/layout/PageHeader"
import Divider from "../components/layout/Divider"
import SEO from "../components/layout/SEO"
import AnimatedScrollWrapper from "../components/animation/AnimatedScrollWrapper"
import VacancyList from "../components/VacancyList"

// Config
import { config as defaultPageTransitionsConfig } from "../components/pageTransitions/config"

interface IProps {
  data: IPage
  vacancies: IVacancy[]
  slices: ISlice[]
}

// Setup
const pageTransitionsConfig = {
  ...defaultPageTransitionsConfig,
  particle: {
    ...defaultPageTransitionsConfig.particle,
    fillStyle: colors.white,
  },
}

const AboutPage = ({ data, vacancies, slices }: IProps) => {
  const page = useContext(PageContext)

  useEffect(() => {
    page.updateColor(colors.black)
    page.updateTransition(pageTransitionsConfig)
  }, [])

  return (
    <Wrapper>
      <SEO title={data.metaTitle} description={data.metaDescription} />

      {data && (
        <PageHeader
          pageTitle={data.pageTitle}
          introTitle={data.introTitle}
          introCopy={data.introCopy}
        />
      )}

      <Container>
        <VacancyListWrapper>
          <Row>
            <Col m={14} mOffset={2}>
              <VacancyListHeader>
                <AnimatedScrollWrapper>
                  <Divider color={colors.white} />
                </AnimatedScrollWrapper>

                <AnimatedScrollWrapper>
                  <VacanciesListTitle>Current vacancies</VacanciesListTitle>
                </AnimatedScrollWrapper>
              </VacancyListHeader>
            </Col>
          </Row>

          <Row>
            <Col m={14} mOffset={3}>
              <VacancyList vacancies={vacancies} />
            </Col>
          </Row>
        </VacancyListWrapper>
      </Container>

      {slices &&
        slices.length &&
        slices.map((slice: ISlice) => {
          const hasSpacing = slice.data.hasSpacing

          const props = {
            // Assign a default
            isSpaced: "on" as IOnOffType,
            // Override if condition passes
            ...(hasSpacing === "off" && {
              isSpaced: slice.data.hasSpacing,
            }),
          }

          return (
            <Section key={slice.id} {...props}>
              {renderSlice(slice)}
            </Section>
          )
        })}
    </Wrapper>
  )
}

// Utility styles
const Section = styled.section<{ isSpaced?: IOnOffType }>`
  margin-bottom: var(--base-column-size);

  ${mq.from.L`
    margin-bottom: ${(props: { isSpaced?: IOnOffType }) =>
      props.isSpaced === "on" ? "calc(100vh / 3)" : "0"};
  `}

  /* Tablets only */
  @media (min-width: ${mq.sizes.L}px) and (orientation: portrait) {
    margin-bottom: ${(props: { isSpaced?: IOnOffType }) =>
      props.isSpaced === "on" ? "calc(100vh / 4)" : "0"};
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

// Main styles
const Wrapper = styled.div`
  color: ${colors.white};
`

const VacancyListWrapper = styled.div`
  margin-bottom: calc(2 * var(--base-column-size));
`

const VacancyListHeader = styled.div`
  margin-bottom: var(--base-column-size);
`

const VacanciesListTitle = styled.h2`
  ${textStyles.headingM};
  margin-bottom: 0;
`

export default AboutPage
