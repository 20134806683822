import React from "react"
import styled from "styled-components"

// Types
import { IVideo } from "../types/"

const Video = ({ url, hasControls = true }: IVideo) => {
  return (
    <Wrapper>
      <VideoWrapper src={url} autoPlay loop muted controls={hasControls}>
        <p>
          If you are reading this, it is because your browser does not support
          the HTML5 video element.
        </p>
      </VideoWrapper>
    </Wrapper>
  )
}

// Main styles
const Wrapper = styled.div`
  margin-bottom: var(--base-column-size);
`

const VideoWrapper = styled.video`
  display: block;
  width: 100%;
`

export default Video
