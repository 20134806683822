import React from "react"
import styled from "styled-components"

// Types
import { IQuote, QuoteSizeType } from "../types/"

// Styling
import { fontWeights } from "../styles/fonts"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import { Row, Col } from "./layout/Grid"

interface IProps extends IQuote {
  className?: string
}

// Components
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import QuoteSVG from "./icons/quote"

const Quote = ({ className, copy, author, size }: IProps) => (
  <Wrapper className={className}>
    <Row>
      {size && size === "large" ? (
        <>
          <Col xxs={1} m={1} mOffset={1}>
            <AnimatedScrollWrapper>
              <QuoteSVG width={60} height={48} color={colors.black} />
            </AnimatedScrollWrapper>
          </Col>

          <Col xxsOffset={1} xxs={6} m={9}>
            {copy && copy.html && (
              <Copy size={size}>
                <AnimatedScrollWrapper>
                  <div dangerouslySetInnerHTML={{ __html: copy.html }} />
                </AnimatedScrollWrapper>
              </Copy>
            )}

            {author && (
              <Author>
                <AnimatedScrollWrapper>{author}</AnimatedScrollWrapper>
              </Author>
            )}
          </Col>
        </>
      ) : (
        <>
          <Col xxs={1} m={1} mOffset={3}>
            <AnimatedScrollWrapper>
              <QuoteSVG width={40} height={32} color={colors.black} />
            </AnimatedScrollWrapper>
          </Col>

          <Col xxsOffset={1} xxs={6} m={9}>
            {copy && copy.html && (
              <Copy size={size}>
                <AnimatedScrollWrapper>
                  <div dangerouslySetInnerHTML={{ __html: copy.html }} />
                </AnimatedScrollWrapper>
              </Copy>
            )}

            {author && (
              <Author size={size}>
                <AnimatedScrollWrapper>{author}</AnimatedScrollWrapper>
              </Author>
            )}
          </Col>
        </>
      )}
    </Row>
  </Wrapper>
)

// Main styles
const Wrapper = styled.div`
  margin-bottom: calc(var(--base-column-size) / 2);
`

const Copy = styled.div<{ size: QuoteSizeType }>`
  margin-bottom: calc(var(--base-column-size) / 6);

  p {
    ${({ size }) => size === "large" && textStyles.headingM};
    font-weight: ${fontWeights.medium};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Author = styled.div<{ size?: QuoteSizeType }>`
  ${({ size }) => size === "small" && `color: ${colors.accent1};`}
`

export default Quote
